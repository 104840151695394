///
/// @file  _aveda-base-print.scss
///
/// \brief Pull all parts of the theme scss together into one file
///

.site-content {
  width: 90%;
  margin: 0 auto;
}

#favorites {
  * {
    transition: none !important;
  }
  .site-header {
    &__cart,
    &__promo,
    &__offer,
    &__content,
    &__menu-loyalty,
    &__menu-list--mobile,
    &__trustmark-wrapper,
    &__menu-desktop,
    &__menu-item {
      display: none;
    }
    &__menu-list--desktop {
      text-align: center;
    }
    &__menu-wrapper {
      background: transparent;
      height: auto;
      width: 100%;
    }
    &__utility {
      &-right {
        display: none;
      }
      &-left {
        text-align: center;
        .icon--hamburger {
          display: none;
        }
      }
    }
    &__menu-logo {
      float: none;
    }
  }
  .account-section {
    padding-top: 80px;
  }
  .account-section__sidebar,
  .favorite__list-actions,
  .favorite-sign-in,
  .site-footer {
    display: none;
  }
  .favorites-page__content .favorite {
    height: auto !important;
    margin-bottom: 30px;
  }
}
